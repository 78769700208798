import React from "react";
import FiberWorkIcon from "../../img/icon/roof_color.png";
import BuildingConstructionIcon from "../../img/icon/hook_color.png";
import RoadsIcon from "../../img/icon/engineer_color.png";
import StainlessSteelWorksIcon from "../../img/icon/stainless_color.png";
import EarthMoversIcon from "../../img/icon/excavator_color.png";
import PaintingIcon from "../../img/icon/paint_color.png";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const OurServices = () => {
  return (
    <Box>
      <section className="our_service_area">
      <div className="left_service">
        <div className="left_service_inner">
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={FiberWorkIcon} alt="" />
                <img src={FiberWorkIcon} alt="" />
              </div>
              <a href="#">
                <h4>Fiber Work</h4>
              </a>
              <p>
                Roof, gutters for water leakage. Down pipes, fiber plane sheets
              </p>
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={BuildingConstructionIcon} alt="" />
                <img src={BuildingConstructionIcon} alt="" />
              </div>
              <a href="#">
                <h4>Building CONSTRUCTION</h4>
              </a>
              {/* <p>Plumbing</p> */}
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={RoadsIcon} alt="" />
                <img src={RoadsIcon} alt="" />
              </div>
              <a href="#">
                <h4>Roads</h4>
              </a>
              <p>Tar Roads</p>
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={StainlessSteelWorksIcon} alt="" />
                <img src={StainlessSteelWorksIcon} alt="" />
              </div>
              <a href="#">
                <h4>Stainless Steel Works</h4>
              </a>
              <p>Gutters , Down pipes and Box bar works</p>
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={EarthMoversIcon} alt="" />
                <img src={EarthMoversIcon} alt="" />
              </div>
              <a href="#">
                <h4>Earth Movers</h4>
              </a>
              <p>
                Filling land with soil, building demolishing and clearing work
              </p>
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
          <div className="service_item">
            <div className="service_item_inner">
              <div className="service_icon">
                <img src={PaintingIcon} alt="" />
                <img src={PaintingIcon} alt="" />
              </div>
              <a href="#">
                <h4>Painting</h4>
              </a>
              <p>All kinds of painting work</p>
              <a className="view_btn" href="#">
                Read Details
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="right_service">
        <div className="right_service_text">
          <Typography variant="h2" color="white">
            OUR <br /> SERVICES
          </Typography>
          <Typography variant="h4" color="white" my={1}>
            The Best Construction <br /> Company
          </Typography>

          <Typography variant="body1" color="initial">
            Discover our comprehensive range of services designed to meet your
            construction and maintenance needs. From fiber work and building
            construction to road development and stainless steel works, we offer
            a diverse set of solutions.
          </Typography>

          <Typography variant="body1" color="initial">
            Our skilled team of professionals is committed to delivering
            high-quality results. Whether it's roofing, plumbing, tar roads, or
            earth movers, we have the expertise and experience to handle any
            project.
          </Typography>
          <Typography variant="body1" color="initial">
            With attention to detail and a focus on customer satisfaction, we
            ensure that every job is completed to the highest standards. Explore
            our services below and let us bring your vision to life.
          </Typography>
        </div>
      </div>
    </section>
    </Box>
  );
};

export default OurServices;
