import React, { useState } from "react";
import HeroSlider, { Overlay, Slide } from "hero-slider";

//images
import slider1 from "../../img/home-slider/slider-1.jpg";
import slider2 from "../../img/home-slider/slider-2.jpg";
import Subtitle from "../core/subtitle";
import Wrapper from "../core/wrapper";
import Title from "../core/title";
import {
  Box,
  Container,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const titleAndSubtitle = [
  { title: "We are R & P Construction", sub_title: "We innovate" },
  {
    sub_title: "Constructing Dreams",
    title: "Transforming Visions into Reality",
  },
];

const MainSlider = () => {
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const slides = [slider1, slider2];

  // const goToPrevious = () => {
  //   const isFirstSlide = currentIndex === 0;
  //   const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
  //   setCurrentIndex(newIndex);
  // };
  // const goToNext = () => {
  //   const isLastSlide = currentIndex === slides.length - 1;
  //   const newIndex = isLastSlide ? 0 : currentIndex + 1;
  //   setCurrentIndex(newIndex);
  // };
  // const goToSlide = (slideIn: any) => {
  //   setCurrentIndex(slideIn);
  // };

  // return (
  //   <div className="slider_styles">

  //     <div
  //       className="slider_style"
  //       style={{
  //         backgroundImage: `url(${slides[currentIndex]})`,
  //       }}
  //     >
  //     <div className="title_container">
  //     <div className="main_b_title">
  //         <h2>{titleAndSubtitle[currentIndex].title} </h2>
  //       </div>
  //       <div className="main_b_title">
  //       <h6>{titleAndSubtitle[currentIndex].sub_title} </h6>
  //       </div>
  //     </div>
  //     </div>
  //     {/* <div className="arrows">
  //    <div className="leftArrowStyles" onClick={goToPrevious}>❰</div>
  //     <div className="rightArrowStyles" onClick={goToNext}>❱</div>

  //    </div> */}
  //     <div className="dotsContainerStyles">
  //       {slides.map((slide, slideIndex) => (
  //         <div
  //           className="dotStyle"
  //           key={slideIndex}
  //           onClick={() => goToSlide(slideIndex)}
  //         >
  //           ●
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        width:"100%",
        content: {
          xs: `url(${slider2})`,
        },
        objectFit: "cover",
      }}
    ></Box>
  );
  // let theme = createTheme();
  // theme = responsiveFontSizes(theme);

  // return (
  //   <ThemeProvider theme={theme}>
  //     <Box
  //       sx={{
  //         maxHeight: "100vh",
  //         width: "100%",
  //         position: "relative",
  //         overflow: "hidden",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           position: "absolute",
  //           top: "50%",
  //           left:{
  //             xs: "30%",
  //             md: "40%",
  //           },
  //           transform: "translate(-40%, -40%)",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Typography
  //           variant="h2"
  //           color="black"
  //           fontWeight={500}
  //           sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
  //         >
  //           Constructing the Future
  //         </Typography>
  //         <Typography
  //           variant="h3"
  //           color="black"
  //           sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
  //         >
  //           Engineering Excellence Since 1993{" "}
  //         </Typography>
  //         {/* Add any call-to-action buttons here */}
  //       </Box>
  //       <Box
  //         sx={{
  //           maxHeight: "100vh",
  //           width: "100%",
  //           content: {
  //             xs: `url(${slider2})`,
  //           },
  //           objectFit: "cover",
  //         }}
  //       ></Box>
  //     </Box>
  //   </ThemeProvider>
  // );
};

export default MainSlider;
