import React from "react";
import logo from "../../img/logo_3.png";
import recentPostImage1 from "../../img/blog/recent-post/recent-1.png";
import recentPostImage2 from "../../img/blog/recent-post/recent-2.png";

const Footer = () => {
  return (
    <footer className="footer_area">
      <div className="footer_widgets_area">
        <div className="container">
          <div className="row footer_widgets_inner">
            <div
            // className="col-md-3 col-sm-6"
            >
              <div
                className="f_widget about_widget"
                style={{
                  marginTop: "20px",
                  // display: 'flex',
                  // justifyContent:"center"
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    maxWidth: "20%",
                    // maxHeight: "50%"
                    height: "50",
                  }}
                />
                <p>
                  Building dreams, one project at a time. Contact R & P
                  Construction for all your construction needs
                </p>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copy_right">
        <div className="container">
          <h4>
            Copyright &copy;
            <script
              data-cfasync="false"
              src="https://preview.colorlib.com/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
            ></script>
            <script>document.write(new Date().getFullYear());</script> All
            rights reserved | This Website is made with{" "}
            <i className="fa fa-heart-o" aria-hidden="true"></i> by{" "}
            <a href="https://colorlib.com/" target="_blank">
              Exzitan
            </a>
          </h4>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
