import React from "react";
import project1 from "../../img/clients/ansell.png";
import project2 from "../../img/clients/hands.png";
import project3 from "../../img/clients/loadstar.png";
import project4 from "../../img/clients/rda.jpeg";

import { Box, Container, Grid, Typography } from "@mui/material";

const OurClients = () => {
  return (
    <Box my={5}>
      <Container>
        <Typography variant="h2" color="initial" fontWeight={500}>
          Our Clients
        </Typography>
      </Container>
      <Container>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Box
              
              sx={{
                content: {
                  xs: `url(${project1})`,
                },
                height:{
                  xs:"200px",
                  md:"250px",
                }
              }}
            ></Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                content: {
                  xs: `url(${project2})`,
                },
                height:{
                  xs:"200px",
                  md:"250px",
                }
              }}
            ></Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                content: {
                  xs: `url(${project3})`,
                },
                height:{
                  xs:"200px",
                  md:"250px",
                }
              }}
            ></Box>
          </Grid>{" "}
          <Grid item>
            <Box
              height={300}
              sx={{
                content: {
                  xs: `url(${project4})`,
                },
                height:{
                  xs:"200px",
                  md:"250px",
                }
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurClients;
