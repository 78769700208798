import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const CallToAction = () => {
  const theme = useTheme();

  return (
    <Box 
    py={"25px"} 
    px={1.5}
    bgcolor={"#1e2126"}
    >
      <Grid
        container
        alignItems={"center"}
        rowSpacing={2}
        columnSpacing={2}
        justifyContent={"center"}
      >
        <Grid item md={8} xs={12}>
          <Typography variant="h4" color="white" mb={2}>
            Looking for a quality and affordable constructor for your next
            project?
          </Typography>

          <Typography variant="h5" color="white">
            Get in touch with us today to discuss your requirements.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            // margin={"auto"}
            //  bgcozlor={"green"}
            sx={{
              [theme.breakpoints.down("md")]: {
                justifyContent: "center",
              },
            }}
          >
            <a className="get_btn" href="#">
              Call us
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallToAction;
