import React from "react";
import placeIcon from "../../img/icon/place-icon.png";
import phoneIcon from "../../img/icon/phone-icon.png";
import inboxIcon from "../../img/icon/inbox-icon.png";
import { Box, Container, Grid, Typography } from "@mui/material";

const Details = () => {
  return (
    <Box bgcolor={"#00aeef"} py={4}>
      <Container>
        <Grid container rowSpacing={4} alignItems={"center"}>
          <Grid item xs={12} md={4}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Box marginRight={2}>
                <img src={placeIcon} alt="" />
              </Box>
              <Box>
                <Typography variant="h4" color="initial">
                  Office Address
                </Typography>
                <Typography variant="h5" color="initial">
                  305/4 Main Street, Negombo
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Box marginRight={2}>
                <img src={phoneIcon} alt="" />
              </Box>
              <Box>
                <Typography variant="h5" color="initial">
                  0770189121 Roy (CEO)
                </Typography>
                <Typography variant="h5" color="initial">
                  0775591001 Sankalpa
                </Typography>
                <Typography variant="h5" color="initial">
                  0312233648 Land Line
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Box marginRight={2}>
                <img src={inboxIcon} alt="" />
              </Box>
              <Box>
                <Typography variant="h5" color="initial">
                  randpconstruction01@gmail.com{" "}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Details;
