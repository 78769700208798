import React from "react";
import placeholderImage from "../../img/team/owner.png";
import { Box, Container, Grid, Typography } from "@mui/material";

const OurExperience = () => {
  return (
    <Box my={5}>
      <Container>
        <Grid container alignItems={"center"} rowSpacing={2} columnSpacing={4}>
          <Grid item md={6} xs={12}>
            <div
              className="chairman_image"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <img
                src={placeholderImage}
                alt="Chairman"
                style={{
                  maxWidth: "400px",
                  maxHeight: "400px",
                  width: "auto",
                  height: "auto",
                }}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className="our_experience_content">
              <Typography variant="h2" color="initial" marginBottom={2}>
                The Mastermind
              </Typography>

              <Typography
                variant="body1"
                color="initial"
                fontSize={15}
                marginBottom={2}
              >
                With a legacy spanning over three decades, R & P Construction
                has established itself as a reputable leader in the industry.
                Led by Mr. Roy, our esteemed chairman, our company has
                flourished since its establishment in 1993 with business
                registration number WV 2087. Mr. Roy, a highly accomplished
                professional, brings a wealth of experience and expertise to our
                organization. Here are some additional details about our
                chairman:
              </Typography>
              <Box>
                <ul>
                  <li>
                    <Typography
                      variant="body1"
                      color="initial"
                      fontSize={15}
                      fontWeight={500}
                    >
                      Mr. Roy has been instrumental in leading R & P
                      Construction to successfully complete various projects,
                      including prestigious assignments at companies like Ansell
                      and Hands. His guidance and vision have ensured the timely
                      and efficient execution of these projects.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      color="initial"
                      fontSize={15}
                      fontWeight={500}
                    >
                      Prior to his role as the chairman of R & P Construction,
                      Mr. Roy gained valuable experience as an assistant
                      engineer at RDA (Road Development Authority). His tenure
                      at RDA allowed him to contribute to the development of
                      important infrastructure projects.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      color="initial"
                      fontSize={15}
                      fontWeight={500}
                    >
                      Mr. Roy holds a civil engineering diploma from Ampara
                      Hardy Institute, which has provided him with a solid
                      educational foundation in the field of construction and
                      engineering. His expertise and knowledge are invaluable
                      assets to our company.
                    </Typography>
                  </li>
                </ul>
              </Box>
              <Typography
                variant="body1"
                color="initial"
                fontSize={15}
                marginTop={2}
              >
                With Mr. Roy's leadership and expertise, R & P Construction
                continues to thrive and deliver exceptional results in every
                project we undertake.
              </Typography>
              {/* <p className="experience_paragraph">
                Our team of dedicated professionals, including experienced
                engineers and skilled workers, brings a wealth of expertise and
                knowledge to every project. With hands-on experience and a deep
                understanding of the industry, we have successfully completed
                numerous projects, earning the trust and satisfaction of our
                clients.
              </p> */}
              {/* <p className="experience_paragraph">
                At R & P Construction, we prioritize quality, precision, and
                adherence to safety standards. We believe in continuous
                improvement and invest in the ongoing development of our
                workforce, ensuring that we stay up-to-date with the latest
                advancements and best practices in the field.
              </p> */}
              {/* <p className="experience_paragraph">
                Whether you require construction, renovation, or any other
                service, you can rely on our extensive experience and commitment
                to delivering exceptional results. We take pride in our work and
                look forward to partnering with you to bring your vision to
                life.
              </p> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurExperience;
