import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./css/font-awesome.min.css";
import "./css/materialdesignicons.min.css";
import "./css/bootstrap.min.css";
import "./vendors/revolution/css/settings.css";
import "./vendors/revolution/css/layers.css";
import "./vendors/revolution/css/navigation.css";
import "./vendors/animate-css/animate.css";
import "./vendors/owl-carousel/assets/owl.carousel.min.css";
import "./css/style.css";
import "./css/responsive.css";

import OurProjects from "./components/our_projects/our_projects";
import AboutOwner from "./components/our_experience/our_experience";
import Testimonials from "./components/testimonials/testimonials";
import Clients from "./components/clients/clients";
import MapComponent from "./components/map_component/map_component";
import Details from "./components/details/details";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import GetQuoteArea from "./components/get_quote/get_quote";
import OurTeam from "./components/our_team/our_team";
import OurExperience from "./components/our_experience/our_experience";
import OurServices from "./components/our_services/our_services";
import CallToAction from "./components/call_to_action/call_to_action";
import StatsComponent from "./components/stats_component/stats_component";
import OurClients from "./components/our_clients/our_clients";
import Slider from "./components/main_slider/main_slider";
import MainSlider from "./components/main_slider/main_slider";
import { Box } from "@mui/material";
import ResponsiveAppBar from "./components/header/responsive_header";

function App() {
  const [activeComponents, setActiveComponents] = useState([]);

  // Function to update active components
  const handleHeaderClick = (componentName) => {
    if (activeComponents.includes(componentName)) {
      setActiveComponents(
        activeComponents.filter((comp) => comp !== componentName)
      );
    } else {
      setActiveComponents([...activeComponents, componentName]);
    }
  };
  return (
    <div className="App">
      <div>
        {/* <Header  /> */}
        <ResponsiveAppBar/>
        <Box id="main-slider">
          <MainSlider />
        </Box>
        <Box id="call-to-action">
          <CallToAction />
        </Box>
        <Box id="our-team">
          <OurTeam />
        </Box>
        {/* <OurExperience /> */}
        <Box id="our-services">
          <OurServices />
        </Box>
        {/* <OurProjects /> */}
        <Box id="about-owner">
          <AboutOwner />
        </Box>
        <Box id="our-clients">
          <OurClients />
        </Box>
        {/* <GetQuoteArea /> */}

        

        <StatsComponent />
        {/* <Testimonials /> */}
        {/* <Clients /> add later when there are more clients */}
        {/* <MapComponent /> */}
        <Box id="contact_us">
        <Details />
        </Box>
        <Footer />
      </div>
    </div>
  );
}

export default App;
