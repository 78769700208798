import React from "react";
import Typography from "@mui/material/Typography";

const StatsComponent = () => {
  return (
    <section className="counter_area">
      <div className="container">
        <div className="row counter_inner">
          <div className="col-md-3 col-sm-6">
            <div className="counter_item">
              <i className="fa fa-archive" aria-hidden="true"></i>
              <Typography variant="h4" color="white">
                400
              </Typography>

              <Typography variant="h5" color="white">
                Total projects
              </Typography>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="counter_item">
              <i className="fa fa-building-o" aria-hidden="true"></i>
              <Typography variant="h4" color="white">
                50
              </Typography>

              <Typography variant="h5" color="white">
                Houses built
              </Typography>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="counter_item">
              <i className="fa fa-users" aria-hidden="true"></i>
              <Typography variant="h4" color="white">
                25
              </Typography>
              <Typography variant="h5" color="white">
                Experienced staff
              </Typography>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="counter_item">
              <i className="fa fa-smile-o" aria-hidden="true"></i>
              <Typography variant="h4" color="white">
                20
              </Typography>
              <Typography variant="h5" color="white">
                Happy clients
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsComponent;
