import whoWeImage from "../../img/team/member1.png";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";

const OurTeam = () => {
  const theme = useTheme();

  return (
    <Box my={5}>
      <Container>
        <Grid
          container
          alignItems={"center"}
          // style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h2" color="initial" fontWeight={400}>
                {" "}
                Meet <br className="title_br" /> Our Team
              </Typography>
              <Typography variant="h4" color="initial" marginBottom={4}>
                Exceptional Professionals
              </Typography>

              <Typography variant="body1" color="initial" fontSize={15}>
                <strong>Our Qualified Staff:</strong> At R & P Construction, we
                take pride in our team of highly qualified professionals
                dedicated to delivering exceptional results. Our workers have
                gone through rigorous training and safety programs conducted by
                the esteemed Ansell Textiles Lanka Safety department.
              </Typography>

              <List>
                <ListItem>
                  <Typography variant="body1" color="initial" fontSize={15}>
                    <strong>Safety First:</strong> Our workers have received
                    comprehensive training on safety protocols, emphasizing the
                    importance of using safety belts and helmets. They are
                    well-versed in implementing safety measures to ensure a
                    secure working environment.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" color="initial" fontSize={15}>
                    <strong>Expertise in Tool Usage:</strong> Our staff is
                    proficient in handling a wide range of electric tools.
                    Through training programs, they have gained the necessary
                    knowledge and skills to operate these tools efficiently and
                    safely.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" color="initial" fontSize={15} mb={4}>
                At R & P Construction, we prioritize the well-being of our staff
                and strive to maintain a culture of safety and professionalism.
                You can trust that our qualified team is fully equipped to
                handle your project with utmost precision and adherence to
                safety standards. Feel free to reach out to us for any inquiries
                or to discuss your project requirements.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                maxHeight: "600px",

                [theme.breakpoints.down("md")]: {
                  maxHeight: "400px",
                },
                content: {
                  xs: `url(${whoWeImage})`,
                },
              }}
              component={"img"}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurTeam;
